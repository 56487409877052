const NOTHING_TO_DO = 'Nothing to do'
const USER_ALREADY_EXISTS = 'The user already exists.'
const CS_AUTH0_CLAIM_ID = process.env.VUE_APP_CS_AUTH0_CLAIM_ID
const HASURA_AUTH0_CLAIM_ID = process.env.VUE_APP_HASURA_AUTH0_CLAIM_ID
const API_KEY_SECRET = process.env.VUE_APP_BE_API_KEY || ''
const SERVICE_API = process.env.VUE_APP_SERVICE_API

export const SHOW_MAINTENANCE = process.env.VUE_APP_SHOW_MAINTENANCE || false

// export const REST_API_V2 = process.env.VUE_APP_REST_API_V2
const REST_API = process.env.VUE_APP_REST_API
// // TODO: remove once this has been tested and working in staging
// if (process.env.VUE_APP_ENV === 'staging') {
//     REST_API = REST_API_V2
// }
const DEBUG = process.env.VUE_APP_DEBUG
const MAX_FILE_SIZE = process.env.VUE_APP_MAX_FILE_SIZE
const DEFAULT_TIMEZONE = 'Australia/Sydney'
const MAX_LABEL_LENGTH = 20

// we can always add more statuses, right now let's just do active and deactivated (invited shouldn't show anyway)
export const USER_STATUS_TO_LIST = ['ACTIVE', 'DEACTIVATED']
export const APP_API_V2 = process.env.VUE_APP_APP_API_V2

// this is the roundtrip time for 3G network
export const RTT_3G = 600

export const DEFAULT_POINTS_TYPE = 'MANUAL'
export const POINTS_TYPES = {
    AUTO: 'Auto-generated',
    MANUAL: 'Manual'
}

// export const WORKSHOP_TYPES = [
//     'Mental Health',
//     'Vocation / Education Workshops',
//     'Basic Needs (ozharvest)',
//     'Practical Skills (cooking, barbering etc)',
//     'Dance',
//     'Sport',
//     'Art (graphic design/ graf)',
//     'Multimedia workshops (videography/ podcasts)',
//     'Music (Lyrics, DJing, Music production)',
//     'Studio (Audio engineering)',
//     'Events (event management, preparation, performance skills)',
// ]

export const WORKSHOP_TYPES = [
    { value: 'MENTAL_HEALTH', text: 'Mental Health'},
    { value: 'VOCATION_EDUCATION_WORKSHOPS', text: 'Vocation / Education Workshops'},
    { value: 'BASIC_NEEDS', text: 'Basic Needs (ozharvest)'},
    { value: 'PRACTICAL_SKILLS', text: 'Practical Skills (cooking, barbering etc)'},
    { value: 'DANCE', text: 'Dance'},
    { value: 'SPORT', text: 'Sport'},
    { value: 'ART', text: 'Art (graphic design/ graf)'},
    { value: 'MULTIMEDIA_WORKSHOPS', text: 'Multimedia workshops (videography/ podcasts)'},
    { value: 'MUSIC', text: 'Music (Lyrics, DJing, Music production)'},
    { value: 'STUDIO', text: 'Studio (Audio engineering)'},
    { value: 'EVENTS', text: 'Events (event management, preparation, performance skills)'},
]

const DASHA_COLOURS = {
    red: "#E80729",
    pink: "#ef319c",
    orange: "#FF6B00",
    yellow: "#f7b600",
    green: "#29AC64",
    lightGreen: "#7ac31d",
    lightBlue: "#4EBBFF",
    darkBlue: "#0180D5",
    purple: "#6e3ef7",
    black: "#303446"
}

const DASHA_FORMS_COLOURS = [
    DASHA_COLOURS.red,
    DASHA_COLOURS.lightBlue,
    DASHA_COLOURS.yellow,
]



const DASHA_VISITS_BY_SITES_COLOURS = [
    DASHA_COLOURS.red,
    DASHA_COLOURS.pink,
    DASHA_COLOURS.orange,
    DASHA_COLOURS.yellow,
    DASHA_COLOURS.lightGreen,
    DASHA_COLOURS.lightBlue,
    DASHA_COLOURS.darkBlue,
    DASHA_COLOURS.purple,
    DASHA_COLOURS.black
]

const DASHA_VISITS_BY_WORKSHOP_TYPES_COLOURS = [
    DASHA_COLOURS.red,
    DASHA_COLOURS.pink,
    DASHA_COLOURS.orange,
    DASHA_COLOURS.yellow,
    DASHA_COLOURS.lightGreen,
    DASHA_COLOURS.lightBlue,
    DASHA_COLOURS.darkBlue,
    DASHA_COLOURS.purple,
    DASHA_COLOURS.black
]

const DASHA_REFERRAL_COLOURS = [
    DASHA_COLOURS.lightBlue,
    DASHA_COLOURS.darkBlue,
    DASHA_COLOURS.green,
    DASHA_COLOURS.lightGreen,
    DASHA_COLOURS.yellow,
    DASHA_COLOURS.red,
]


/**
 * DOnt know: red
 * Female: pink :pepepls:  i wanna use pink
 * Intersex: orange (see im making rainbow :smile:)
 * Male: yellow
 * Non binary: black
 * Other: light blue
 * transgender female: light green
 * transgender male: green (edited)
 */
const DASHA_GENDER_COLOURS = [
    DASHA_COLOURS.red,
    DASHA_COLOURS.pink,
    DASHA_COLOURS.orange,
    DASHA_COLOURS.yellow,
    DASHA_COLOURS.black,
    DASHA_COLOURS.lightBlue,
    DASHA_COLOURS.lightGreen,
    DASHA_COLOURS.green
]
const IDLE_TIME = (30 * 60 + 1) * 1000
// const IDLE_TIME = (50 + 1) * 1000

export const DEFAULT_TREATMENT_TYPES = {
    mainType: 'SUPPORT_CASE_MANAGEMENT',
    otherTypes: []
}

export const TEST_PROGRESS_DATA = {
    "DSM-V": [
        {
            "form": "DSM-V",
            "score": 8,
            "stage": 0
        }
    ],
    "FAD": [
        {
            "form": "FAD",
            "score": 33,
            "stage": 0
        }
    ],
    "K10": [
        {
            "form": "K10",
            "score": 37,
            "stage": 0
        },
        {
            "form": "K10",
            "score": 35,
            "stage": 1
        },
        {
            "form": "K10",
            "score": 33,
            "stage": 2
        },
        {
            "form": "K10",
            "score": 34,
            "stage": 3
        },
        {
            "form": "K10",
            "score": 30,
            "stage": 4
        },
        {
            "form": "K10",
            "score": 40,
            "stage": 5
        },
        {
            "form": "K10",
            "score": 30,
            "stage": 6
        },
        {
            "form": "K10",
            "score": 27,
            "stage": 7
        },
        {
            "form": "K10",
            "score": 25,
            "stage": 8
        },
        {
            "form": "K10",
            "score": 26,
            "stage": 9
        },
        {
            "form": "K10",
            "score": 25,
            "stage": 10
        },
        {
            "form": "K10",
            "score": 22,
            "stage": 11
        }
    ],
    "SDS": [
        {
            "form": "SDS",
            "score": 11,
            "stage": 0
        },
        {
            "form": "SDS",
            "score": 10,
            "stage": 1
        },
        {
            "form": "SDS",
            "score": 6,
            "stage": 2
        },
        {
            "form": "SDS",
            "score": 8,
            "stage": 3
        },
        {
            "form": "SDS",
            "score": 5,
            "stage": 4
        },
        {
            "form": "SDS",
            "score": 5,
            "stage": 5
        },
        {
            "form": "SDS",
            "score": 3,
            "stage": 6
        },
        {
            "form": "SDS",
            "score": 6,
            "stage": 7
        },
        {
            "form": "SDS",
            "score": 5,
            "stage": 8
        },
        {
            "form": "SDS",
            "score": 6,
            "stage": 9
        },
        {
            "form": "SDS",
            "score": 5,
            "stage": 10
        },
        {
            "form": "SDS",
            "score": 6,
            "stage": 11
        }
    ],
    "TOC": [
        {
            "form": "TOC",
            "score": 0,
            "stage": 1
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 2
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 3
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 4
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 5
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 6
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 7
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 8
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 9
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 10
        },
        {
            "form": "TOC",
            "score": 0,
            "stage": 11
        }
    ],
    "WHO-8": [
        {
            "form": "WHO-8",
            "score": 19,
            "stage": 0
        },
        {
            "form": "WHO-8",
            "score": 16,
            "stage": 1
        },
        {
            "form": "WHO-8",
            "score": 25,
            "stage": 2
        },
        {
            "form": "WHO-8",
            "score": 20,
            "stage": 3
        },
        {
            "form": "WHO-8",
            "score": 23,
            "stage": 4
        },
        {
            "form": "WHO-8",
            "score": 25,
            "stage": 5
        },
        {
            "form": "WHO-8",
            "score": 30,
            "stage": 6
        },
        {
            "form": "WHO-8",
            "score": 31,
            "stage": 7
        },
        {
            "form": "WHO-8",
            "score": 35,
            "stage": 8
        },
        {
            "form": "WHO-8",
            "score": 29,
            "stage": 9
        },
        {
            "form": "WHO-8",
            "score": 25,
            "stage": 10
        },
        {
            "form": "WHO-8",
            "score": 29,
            "stage": 11
        }
    ]
}
export const AODW_ROLES = ['aodw', 'engagement_worker']

export {
    NOTHING_TO_DO,
    USER_ALREADY_EXISTS,
    CS_AUTH0_CLAIM_ID,
    HASURA_AUTH0_CLAIM_ID,
    API_KEY_SECRET,
    SERVICE_API,
    DEBUG,
    MAX_FILE_SIZE,
    DEFAULT_TIMEZONE,
    DASHA_FORMS_COLOURS,
    DASHA_VISITS_BY_SITES_COLOURS,
    DASHA_VISITS_BY_WORKSHOP_TYPES_COLOURS,
    DASHA_GENDER_COLOURS,
    DASHA_REFERRAL_COLOURS,
    DASHA_COLOURS,
    MAX_LABEL_LENGTH,
    IDLE_TIME,
    REST_API
}
